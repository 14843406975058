const Lang = {
  'en': {
    'header':'Register adress',
    'lead': 'We will need your Adress to send the QR code -stickers to you.',
    'firstName' : 'First Name',
    'lastName' : 'Last name',
    'email' : 'Email',
    'co' : 'Co',
    'coDesc':'if you wish for your delevery to an adress registered to another person',
    'address':'Address',
    'address2' : 'Address 2',
    'address2Desc' : 'Apartment, studio, or floor',
    'city':'City',
    'state':'State/provice',
    'zip':'Zip/postal code',
    'country':'Country',
    'save' : 'Save adress for future use',
    'send' : 'Send'
  },
  'se': {
    'header':'Registera adress',
    'lead': 'Vi kommer att behöva din adress så vi kan skicka QR kodsklistermärket till er.',
    'firstName' : 'Förnamn',
    'lastName' : 'Efternamn',
    'email' : 'Epost',
    'co' : 'Co',
    'coDesc':'Om adressen vi ska skicka till är skriven på en annan person',
    'address':'Adress',
    'address2' : 'Adress 2',
    'address2Desc' : 'lägenhets nummer eller våning',
    'city':'Stad',
    'state':'Län',
    'zip':'Post nummer',
    'country':'Land',
    'save' : 'Spara adressen för framtida köp',
    'send' : 'Skicka'
  }
}

export default Lang;