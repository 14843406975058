import "./Profile.css";
import React, { useEffect,useState } from "react";
import { NavLink } from "react-router-dom";
import img from './person.png';
import lang from './Lang';

export default function Profile(props) {
  const [currLang, setcurrLang] = useState(lang['en']);
  const user = props.user;

  useEffect(() => {
    if (props.language) setcurrLang(lang[props.language]);
  }, [props.language]);

  if(user.img) { img = user.img}

  return (
    <div className="btn-group">
      <button type="button" id="user" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
        <img alt="User" src={img} />
      </button>
      <div className="dropdown-menu" aria-labelledby="user">
        <div className="text-center font-weight-bold">{currLang.loggedIn}</div>
        <hr />
        <div className="clearfix">
          <div>{user.name}</div>
          <div>{user?.email}</div>
        </div>
        <div className="clearfix">        
          <ul className="navbar-nav flex-column">
            {currLang && Object.keys(currLang['links']).map((key,index) => (
            <li key={index.toString()}>
              <NavLink 
                end 
                className={({ isActive }) => "text-muted" + (isActive ? " active" : "")}               
                to={key}>
                  {currLang['links'][key]}
              </NavLink>
            </li>
            ))}
          </ul>
        </div>
        <hr />
        <button className="btn btn-danger float-right">
        <NavLink to="/logout">{currLang['logout']}</NavLink>
        </button>
      </div>
    </div>

  );
}
