import './Register.css';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../config/firebase-config";
import { 
  FacebookAuthProvider, 
  GoogleAuthProvider, 
  GithubAuthProvider, 
  TwitterAuthProvider 
} from 'firebase/auth';
import { 
  socialMediaAuth, 
  registerWithEmailAndPassword, 
  logInWithEmailAndPassword 
} from '../../../service/auth';
import { addUser } from '../../../service/db';
import lang from './Lang';

const handleOnClick = async (provider) => {
  await socialMediaAuth(provider);
}

export default function Register(props) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [authUser, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['user']);

  const [currLang, setcurrLang] = useState(lang['en']);
  const user = cookies.user;

  const addToDb = async() => {
    const userD = await addUser(authUser);
    if(userD) setCookie('user', userD, { path: '/' });
  }

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (authUser) addToDb();
    if(user && user.name) navigate("/dashboard");
    if (props.language) setcurrLang(lang[props.language]);

  }, [authUser, user, loading]);
  
  return(
    <div className="login-form">
        <h2 className="text-center" >{currLang.header}</h2>
        <div className="text-center social-btn">
          <button onClick={() => handleOnClick(new FacebookAuthProvider())} className="btn btn-primary btn-block"><i className="fa fa-facebook"></i> {currLang.signInWith} <b>Facebook</b></button>
          <button onClick={() => handleOnClick(new TwitterAuthProvider())} className="btn btn-info btn-block"><i className="fa fa-twitter"></i> {currLang.signInWith} <b>Twitter</b></button>
			    <button onClick={() => handleOnClick(new GoogleAuthProvider())} className="btn btn-danger btn-block"><i className="fa fa-google"></i> {currLang.signInWith} <b>Google</b></button>
			    <button onClick={() => handleOnClick(new GithubAuthProvider())} className="btn btn-secondary btn-block"><i className="fa fa-github"></i> {currLang.signInWith} <b>Github</b></button>
        </div>
		    <div className="or-seperator"><i>{currLang.or}</i></div>
        <div className="form-group">
        	<div className="input-group">                
            <div className="input-group-prepend">
              <span className="input-group-text">
                <span className="fa fa-user"></span>
              </span>                    
            </div>
            <input onChange={(e) => setName(e.target.value)} type="text" name="name" className="form-control" placeholder="Jon Doe" required="required" />
          </div>
        </div>
        <div className="form-group">
        	<div className="input-group">                
            <div className="input-group-prepend">
              <span className="input-group-text">
                <span className="fa fa-at"></span>
              </span>                    
            </div>
            <input onChange={(e) => setEmail(e.target.value)} type="email" name="email" className="form-control" placeholder={currLang.email} required="required" />
          </div>
        </div>
        <div className="form-group">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-lock"></i>
              </span>                    
            </div>
            <input onChange={(e) => setPassword(e.target.value)} type="password" name="password" className="form-control" placeholder={currLang.password} required="required" />
          </div>
        </div>        
        <div className="form-group">
          <button type="submit" onClick={() => registerWithEmailAndPassword(name, email, password)} className="btn btn-success btn-block login-btn">{currLang.signIn}</button>
        </div>
      <div className="clearfix">
        <label className="float-left form-check-label"><input onChange={(e) => setEmail(e.target.value)} name="remember" type="checkbox" /> {currLang.remember}</label>
      </div>
      <div className="clearfix">
        {currLang.haveAccount} <Link className="float-right text-success" to="/login">{currLang.login}</Link> 
      </div>
  </div>
  )
}