const Lang = {
  'en': {
    'main': {
      'support': 'Support',
      '/companies' : 'Companies',
      '/contact':'Contact us'
    },
    'sign in': 'Sign in'
  },
  'se': {
    'main': {
      'support': 'Support',
      '/companies' : 'Företag',
      '/contact':'Kontakta oss'
    },
    'sign in': 'Logga in'
  }
}

export default Lang;