import { db } from "../config/firebase-config";
import {
  query,
  getDocs,
  collection,
  where,
  addDoc, 
  updateDoc,
  doc
} from "firebase/firestore";

const addUser =  async (authUser) => {
  const w = authUser.providerData[0].providerId.replace(".com", "");
  let DocRef = await exist(w+ "Id", authUser?.uid);
  if(!DocRef) {
    DocRef = await addDoc(collection(db, "users"), {
      name: authUser.displayName,
      [w+"Email"]: authUser.email,
      [w+"Id"]: authUser.uid,
      email: authUser.email
    });
    return {
      name: authUser.displayName,
      [w+"Email"]: authUser.email,
      [w+"Id"]: authUser.uid,
      email: authUser.email,
      ref: DocRef
    }
  } else {
    alert('You have already registered');
  }
}

const addNonSocUser = async(authUser) => {
  const DocRef = await exist("uid", authUser?.uid);
  if (DocRef) {
    const user = doc(db, "users", DocRef);
    //TODO: add control off email in user obj
    await updateDoc(user, {
      uid: authUser.uid,
      name: authUser.name,
      email: authUser.email,
    });
  } else {
    await addDoc(collection(db, "users"), {
      uid: authUser.uid,
      name: authUser.name,
      email: authUser.email,
    });
  }
}

const exist = async(type, uid) => {
  let q = query(collection(db, "users"), where( type , "==", uid));
  const doc = await getDocs(q);
  return (doc.docs[0]) ? doc.docs[0].id : false;
}
const updateUser =  async(updatedUser) => {
  const dbUser = doc(db, "users", updatedUser.ref);
  await updateDoc(dbUser, updatedUser);
}

const userData = async(authUser) => {
  try {
    const w = authUser.providerData[0].providerId.replace(".com", "");
    const DocRef = await exist(w+ "Id", authUser?.uid);
    if(DocRef) {
      /*
      const existingUser = doc(db, "users", DocRef);
      //TODO: add control off email in user obj
      await updateDoc(existingUser, {
        name: authUser.displayName,
        [w+"Email"]: authUser.email,
        [w+"Id"]: authUser.uid,
        email: authUser.email
      });
      */
      let q = query(collection(db, "products"), where("owner", "==", DocRef));
      const querySnapshot = await getDocs(q);
      let usersProducts ={};
      querySnapshot.forEach((doc) => {
        usersProducts[doc.id] = doc.data();
      });
      localStorage.setItem('products', JSON.stringify(usersProducts));
      return {
        name: authUser.displayName,
        email: authUser.email,
        img: authUser.photoURL,
        ref: DocRef,
      }
    } else {
      // add user ?
      console.log('user is not in db');
    }
  } catch (err) {
    console.log(authUser);
    console.error(err);
    alert("An error occured while fetching user data");
  }

  return false;
}

const saveProduct = async(product) => {
  try {
    if (product['package']) delete product['package'];
    if (product['payNow']) delete product['payNow'];

    if (product.ref) {
      const prod = doc(db, "products", product.ref);
      await updateDoc(prod, product);
    } else {
      const saved = await addDoc(collection(db, "products"), product );
      product.ref = saved.id;
    }
    let products = JSON.parse(localStorage.getItem('products'));
    products[product.ref] = product;
    localStorage.setItem('products', JSON.stringify(products));
    return product['ref'];
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}


export { userData, addUser, addNonSocUser, saveProduct, updateUser };