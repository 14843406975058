import './CookiePopup.css';
import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import parse from 'html-react-parser';
import lang from './Lang';
import Body from './Body';

const Popup = (props) => {
 const currLang = lang[props.language] ? lang[props.language] : lang['en'];

  return (
    <div className="popup">
      <CookieConsent
        disableStyles
        location="none"
        buttonText={currLang.buttonText}
        buttonClasses="btn btn-success mx-auto d-block mt-4"
        cookieName="ConsentApproved"
        overlay
        overlayClasses="overlayclass"
      >
        <Body language={props.language} />
        <br/>
        {parse(currLang.browserLang)}
      </CookieConsent>
    </div>
  );
};

export default Popup;
