const Lang = {
  'en': {
    'header':'Support',
    'lead': 'What can we help you with today ?',
    'answer' : 'Your experience is our concern.'

  },
  'se': {
    'header': 'Support',
    'lead': 'Vad kan vi hjälpa dig med idag ?',
    'answer': 'Vi vill leverera den bästa upplevelsen till er.'
  }
}

export default Lang;