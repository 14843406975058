const Lang = {
  'en': {
    'header':'Page not found',
    'lead': 'Check your url.',
    'body' : '<p>The url is not valid. This might happen in three situations:</p><ul><li>You have tried to reload view of an item contact page.</li><li>The link you clicked is no longer valid.</li><li>you have entered the url your self and missed something.</li></ul>'
  },
  'se': {
    'header':'Sidan går inte att hitta',
    'lead': 'Kontrollera din url.',
    'body' : '<p>Url:en är inte korrekt. Detta kan hända i tre situationer:</p><ul><li>Du har försökt ladda om en produkt sida.</li><li>Länken du har klickat på är inte längre giltigt.</li><li>Du har skrivit in adressen själv och har missat något.</li></ul>'
  }
}

export default Lang;