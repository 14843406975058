const Lang = {
  'en': {
    'header':'Have you ever lost something ?',
    'pricing' : 'Pricing',
    'single':'Single',
    'tripple':'Tripple',
    'm': 'mo',
    'y': 'year',
    'singleBody':'<p>For <b>less than 0.1 € a day</b> you will get 1 QR code on your belonging to ensure that if you lose it, someone can return it to you.</p>',
    'singleAction':'Get started',
    'trippleBody':'<p>For <b>less than 0.2 € per day</b> you will get 3 QR codes to put on your belongings to ensure that if you lose it, someone can return it to you. </p>',
    'trippleAction':'What are you waiting for ?',
    'explain':'Minimum 6 months'

  },
  'se': {
    'header':'Har du någonsin förlorat något ?',
    'pricing' : 'Priser',
    'single':'Enkel',
    'tripple':'Trippel',
    'm': 'må',
    'y': 'år',
    'singleBody':'<p>För <b>mindre än 1 kr om dagen</b> får du 1 QR kod som du kan sätta på valfri sak, tex mobil eller laptop, så personen som hittar den kan returnera den till dig.</p>',
    'singleAction':'Börja här',
    'trippleBody':'<p>För <b>mindre än 2 kr per dag</b> får du 3 QR koder som du kan sätta på dina saker så personen som hittar dom kan returnera dem till dig. </p>',
    'trippleAction':'Klipp till nu !',
    'explain':'Minst 6 månader'
  }
}

export default Lang;

// Har du någonsin tappat något och önskat att det vore något bra sätt att få tillbaka det ?
