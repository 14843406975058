import React, { useEffect, useState } from 'react';
import { logout } from "../../../service/auth";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const [cookies, removeCookie]  = useCookies();
  const navigate = useNavigate();

  const loggingOut = () => {
    logout();
    removeCookie('user');
    navigate('/');
  }  

  useEffect(() => {
    if(cookies.user.name) loggingOut();
  }, [cookies.user]);

  return (
    <div>Logging out...</div>
  )
  
}