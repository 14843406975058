const Lang = {
  'en': {
    'header':'Dashboard',
    'lead': 'Your space',
    'description' : 'Here you will see all your items and status.<br />By regisering your item, you will enter all nessassary information on that product. When you feel ready with you items, you will need to activate them.',
    'regProd' : 'Register product',
    'noProds':'You don\'t have any products yet :(',
    'yourItems':'Your registered items',
    'notRegItems':'You have $$num items that is not activated yet.',
    'linkTextOrder':'click here to fix this now',
    'type':'Type',
    'findFee':'Finders fee',
    'prodInfo':'Information displayed if found',
    'view': 'View',
    'edit': 'Edit'
  },
  'se': {
    'header':'Din sida',
    'lead': 'Dina saker',
    'description' : 'Här ser du alla saker du har registerat och deras status.<br />Genom att registera en sak så skriver du in all nödvädig information till den saken. När du känner dig redo så aktiverar du din sak och tjänsten är igång.',
    'regProd' : 'Registera sak',
    'noProds':'Du har inga registerade saker ännu :(',
    'yourItems':'Dina registerade saker',
    'notRegItems':'du har $$num st saker som inte är aktiverade ännu.',
    'linkTextOrder':'Klicka här för att fixa det nu',
    'type':'Typ',
    'findFee':'Hittelön',
    'prodInfo':'Informationen som visas för personen som hittat din sak',
    'view': 'Granska',
    'edit': 'Redigera'
 }
}

export default Lang;