import "./Dashboard.css";
import React, { useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import QRCode from 'react-qr-code';
import parse from 'html-react-parser';
import lang from './Lang';

export default function Dashboard(props) {
  const navigate = useNavigate();
  const origin = window.location.origin;

  const [currLang, setcurrLang] = useState(lang['en']);

  useEffect(() => {
    if (!props) {
      // maybe trigger a loading screen
      return;
    }
    if (props.language) setcurrLang(lang[props.language]);
    if(!props.user.name) navigate('/');
  }, [props.user]);

  const products = JSON.parse(localStorage.getItem('products'));
  let numProds = 0;
  let payedProds = 0;
  if(products){
    const values = Object.values(products);
    values.forEach((product) => { 
      numProds++;
      if (product.payed) { payedProds++; }
    });
  }

  return (
    <div className="container">
      <h1>{currLang.header}</h1>
      <hr />
        <div className="card">
          <h5 className="card-header">{currLang.lead}</h5>
          <div className="card-body">
            <p className="card-text">{currLang && parse(currLang['description'])}</p>
            <Link to="/product/register" className="btn btn-primary">{currLang.regProd}</Link>
          </div>
        </div>        
      {!products ? (
        <div class="alert alert-primary m-2" role="alert">
          {parse(currLang.noProds)}
        </div>
      ) : (
        <div>
          <div className="mt-4">
            <h3>{currLang.yourItems}</h3>
            <hr/>
          </div>
          { (numProds - payedProds > 1) ? ( 
          <div className="alert alert-warning" role="alert">
            {currLang.notRegItems.replace("$$num", (numProds - payedProds))} 
            <Link to="/order" className="alert-link">{currLang.linkTextOrder}</Link>.
          </div>
          ): ( <div></div>) }
          { Object.keys(products).map((key) => (
            <div key={key} className="card-deck mb-3">
              <div className="card mb-4 shadow-sm">
                <div className="card-header text-center">
                  <h4 className="my-0 font-weight-normal">{products[key]['name']}</h4>
                </div>
                <div className="card-body">
                  <div className="d-flex flex-row">
                    <div className="m-2">
                      <QRCode value={origin + '/' +  key} fgColor={"#000000"} bgColor={"#FFFFFF"} size={128}/>
                    </div>
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <th scope="row">{currLang.type}:</th>
                          <td>{products[key]['prodType']}</td>
                        </tr>
                        <tr>
                          <th scope="row">{currLang.findFee}:</th>
                          <td>{products[key]['findFee']}</td>
                        </tr>
                        <tr>
                          <th scope="row" colSpan="2">{currLang.prodInfo}:</th>
                        </tr>
                        <tr>
                          <td colSpan="2">{products[key]['contactInfo']}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button type="button"  className="btn btn-lg btn-success">
                    <Link to={'/product/' + key} >{currLang.view}</Link>
                  </button>
                  <button type="button" data-productid={key} className="btn btn-lg btn-success float-right">
                    <Link to={'/product/edit/' + key}>{currLang.edit}</Link>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}