
const userPos = async(type) => {
  try {
    const position = await getPosition();
    if(type === "obj") { return position; }

    const viewerPos = 'https://www.google.com/maps/search/?api=1&query='+position.coords.latitude+'%2C'+position.coords.longitude;
    if(type === "link") { return viewerPos; }
  
  } catch (err) {
    console.error(err.message);
  }
} 

function getPosition(options?: PositionOptions): Promise<Position> {
  return new Promise((resolve, reject) => 
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
  );
}

export { userPos }
