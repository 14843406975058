import "./Support.css";
import React, { useEffect,useState } from "react";
import lang from './Lang';
import Body from "./Body";
export default function Support(props) {
  const [currLang, setcurrLang] = useState(lang['en']);

  useEffect(() => {
    if (props.language) setcurrLang(lang[props.language]);
  }, [props.language]);
  
  return (
    <div id="support" className="container">
      <div className="jumbotron">
        <h1 className="display-4">{currLang.header}</h1>
        <p className="lead">{currLang.lead}</p>
        <hr className="my-4" />
        <p>{currLang.answer}</p>
      </div>
      <Body language={props.language} />
    </div>
  )
}
