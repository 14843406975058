const Lang = {
  'en': {
    'header':'You have found an object that someone might have lost!!',
    'notPaid': 'Item not paid for.',
    'notActivated' : 'This item is not activated.',
    'activate' : 'Click here to fix this now.',
    'findFee' :'Finders fee',
    'contact': 'Try contacting the owner thru the information below',
    'item' : 'The object',
    'type':'Type',
    'name':'Name'
  },
  'se': {
    'header':'Du har hittat en sak någon verkar ha förlorat!!',
    'notPaid': 'Produkten är inte betald.',
    'notActivated' : 'Den här produkten är inte aktiverad.',
    'activate' : 'Klicka här för att aktivera.',
    'findFee' :'Hittelön',
    'contact': 'Försök kontakta ägaren genom informationen nedan',
    'item' : 'Produkten',
    'type':'Typ',
    'name':'Namn'
  }
}

export default Lang;