const Lang = {
  'en': {
    'header':'Reset password',
    'email': 'E-mail Address',
    'send' : 'Send password reset email',
    'noAccount' : 'Don\'t have an account?',
    'register':'Register now.'
  },
  'se': {
    'header':'Återställ lösenord',
    'email': 'Epost Adress',
    'send' : 'Skicka lösenords länk',
    'noAccount' : 'Har du inget konto?',
    'register':'Registera dig nu.'
  }
}

export default Lang;