import './Order.css';
import React, { useReducer, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QRCode from 'react-qr-code';
import lang from './Lang';

const formReducer = (state, event) => {
  if(event.reset) {
    let  returnData = {};
    return {
      returnData
    }
  }
  if(event.ref){
    return event;
  }
  return {
    ...state,
    [event.name]: event.value
  }
 }

export default function Order(props) {
  const navigate = useNavigate();
  const [formData, setFormData] = useReducer(formReducer, {});
  const [cost, setCost] = useState({findFee:0, package:0, quantity:0});

  const [currLang, setcurrLang] = useState(lang['en']);
  const [currPrice, setcurrPrice] = useState(lang['en']);

  const origin = window.location.origin;
  const user = props.user;

  useEffect(() => {
    if (!user) return;
    if (!user.name) navigate("/login");
    if (props.language) {
      setcurrLang(lang[props.language]);
      setcurrPrice(props.price[props.language]);
    }
    if(!formData.payment) {
      setFormData({
        name: "payment",
        value: "year",
      });
    }

    if(formData.products){
      let prodPackage = 0;
      let discount = 0;
      const values = Object.values(formData.products);
      let findersFees =0;
      values.forEach((product) => { 
        findersFees += parseInt(product);
      });
      if(formData.payment === "year") { 
        prodPackage = currPrice.single.year * values.length;
        if(values.length % 3 === 0 || values.length > 3 ){
          discount = currPrice.single.year * (values.length / 3);
        }
      } else {
        prodPackage = currPrice.single.month * values.length;
      }


      setCost({
        findFee : findersFees,
        package: prodPackage,
        quantity: (values.length) ? values.length : 0,
        discount: discount,
        total: prodPackage - discount // findersFees
    })
    }

    /*
*/

  }, [user.name, formData, props.language]);

  const handleSubmit = e => {
    e.preventDefault();
    console.log(formData);
    //TODO: validate and send payment + form
  }

  const handleChange = e => {
    if(e.target.type === 'checkbox') {
      if(e.target.checked) {
        setFormData({
          name: "products",
          value: {...formData["products"],[e.target.name]: products[e.target.name]["findFee"]},
        });
      } else {
        let updatedProd = formData.products;
        delete updatedProd[e.target.name];
        setFormData({
          name: "products",
          value: updatedProd,
        });
      }

    } else {
      setFormData({
        name: e.target.name,
        value: e.target.value,
      })
    }
  }
  let products = JSON.parse(localStorage.getItem('products'));

  return (
    <div className="container wrapper">
      <h1>{currLang.header}</h1>
      <div>{currLang.intro}</div>
      <form id="order" onSubmit={handleSubmit}>
      { Object.keys(products).map((key) => (
        <div  key={key} className="form-group row">
          <input type="checkbox" onChange={handleChange} name={key} />
          <QRCode value={origin + '/' +  key} fgColor={"#000000"} bgColor={"#FFFFFF"} size={128}/>
          <table className="table table-bordered table-sm">
            <tbody>
              <tr>
                <th scope="row" style={{width: "130px"}}>{currLang.item}:</th>
                <td>
                  <div>
                    {products[key]['name']},{products[key]['prodType']}
                  </div>
                </td>
                <td style={{width: "90px"}}>          
                  <button type="button"  className="btn btn-sm btn-success">
                    <a href={ 'product/' + key} target="_blank">{currLang.view}</a>
                  </button>
                </td>
              </tr>
              <tr>
                <th scope="row" style={{width: "130px"}}>{currLang.findFee}:</th>
                <td>{products[key]['findFee']}</td>
                <td style={{width: "70px"}}>
                  <button type="button" data-productid={key} className="btn btn-sm btn-success">
                    <a href={'/product/edit/' + key}>{currLang.edit}</a>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
      <div className="clearfix">
        <div  className="form-group row float-right">
          <div className="col-sm-9">
            <div className="form-check">
              <input className="form-check-input" onChange={handleChange} type="radio" name="payment" value="month" />
              <label className="form-check-label" htmlFor="package">
                {currLang.monthly}*
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" onChange={handleChange} checked={formData.payment !== "month"} type="radio" name="payment" value="year" />
              <label className="form-check-label" htmlFor="package" >
                {currLang.year}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix">
          <table className="table table-sm col-sm-6 float-right">
            <tbody>
              { 
              /* <tr>
                <td>{currLang.findFee}</td>
                <td className="text-right">{cost.findFee}</td>
              </tr>  */
              }
              <tr>
                <td>{currLang.package.replace('$$quantity', cost.quantity)}</td>
                <td className="text-right">{cost.package}  {props.price[props.language].sign}</td>
              </tr>
              <tr>
                <td>{currLang.discount}</td>
                <td className="text-right">{cost.discount}  {props.price[props.language].sign}</td>
              </tr>                
              <tr>
                <th>{currLang.total}</th>
                <td className="text-right">{cost.total}  {props.price[props.language].sign}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="clearfix">
          <button type="submit" className="btn btn-success mb-4 float-right">{currLang.order}</button>
        </div>
        <div className="small-desc"><span className="upper">*</span>{currLang.explain}</div>
      </form>
    </div>
  )
}
