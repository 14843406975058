import "./RegProduct.css";
import React, { useReducer, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { saveProduct } from "../../../service/db";
import lang from './Lang';

const formReducer = (state, event) => {
  if(event.reset) {
    let  returnData = {};
    return {
      returnData
    }
  }
  if(event.ref){
    return event;
  }
  return {
    ...state,
    [event.name]: event.value
  }
 }

 export default function RegProduct(props) {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const [formData, setFormData] = useReducer(formReducer, {package: "single"});
  const [cost, setCost] = useState({findFee:0, package:0});
  const [ref, setRef] = useState();

  const [currLang, setcurrLang] = useState(lang['en']);
  const user = props.user;

  function EditForm() {
    const DocRef = pathname.replace('/product/edit/','');
    const products = JSON.parse(localStorage.getItem('products'));

    if(!products[DocRef]){
      console.log("not prod owner or product doesnt exist");
      alert("Error load product");
      navigate("/dashboard");
    }
    setRef(DocRef);
    const editProd = products[DocRef];
    Object.keys(editProd).forEach(key => {
      setFormData({name: key, value: editProd[key]});
    });
  }

  useEffect(() => {
    if (!user) return;
    if (user && !user.name) navigate("/login");
    if (props.language) {
      setcurrLang(lang[props.language]);
      setCost({"total" : props.price[props.language].single.year});
    }
    if (pathname.includes('/product/edit/') && !formData.name) {
      EditForm();
    }

    if(formData.payment === "month") { 
      setCost({"total" : props.price[props.language].single.month});
    } else {
      setCost({"total" : props.price[props.language].single.year});
    }

  }, [user.name, formData]);

  const handleSubmit = e => {
    e.preventDefault();
    registerProduct(formData);
  }

  const handleChange = e => {
    const isCheckbox = e.target.type === 'checkbox';
    setFormData({
      name: e.target.name,
      value: isCheckbox ? e.target.checked : e.target.value,
    })
  }

  const registerProduct = async (data) => {
      let dest = "/dashboard";
      data['owner'] =  user.ref;
      if(ref) data['ref'] = ref;
      let w = await saveProduct(data);
      if(data.payNow === 'true') {
        dest = "/user/register-adress";
        const adress = JSON.parse(localStorage.getItem('adress'));
        if(adress.email) dest = "/order";
        localStorage.setItem('buy', w.ref);
      }

      navigate(dest);
  };

  return (
    <div className="container product-form">
      <form onSubmit={handleSubmit}>
        <h2 className="text-center" >
          {formData.ref ? ( currLang.headerEdit ) : ( currLang.headerReg ) }
        </h2>
        <div className="text-center m-2">{currLang.lead}</div>
          <div className="form-group row">
            <label htmlFor="name" className="col-sm-3 col-form-label">
              {currLang.name}:
            </label>
            <div className="col-sm-9">                
              <input 
                name="name" 
                onChange={handleChange} 
                className="form-control" 
                placeholder="Iphone / Samsung"
                required="required" 
                value={formData['name']}
              />
              <div>
                <small className="form-text text-muted">{currLang.nameDesc}</small> 
              </div>            
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="findFee" className="col-sm-3 col-form-label">
              {currLang.findFee}:
            </label>
            <div className="col-sm-9">                
              <input 
                name="findFee" 
                onChange={handleChange} 
                className="form-control" 
                placeholder="500"
                required="required" 
                value={formData['findFee']}
              />
              <div>
                <small className="form-text text-muted">{currLang.findFeeDesc}</small> 
              </div>            
            </div>
          </div>
        <div className="form-group row">
          <label htmlFor="type" className="col-sm-3 col-form-label">
            {currLang.type}:
          </label>
          <div className="col-sm-9">   
            <select name="type" required="required" className="form-control" id="selectType">
              <option value="" >{currLang.select}</option>
              <option value="Mobile" >{currLang.mobile}</option>
              <option value="Laptop" >{currLang.laptop}</option>
              <option value="Other" >{currLang.other}</option>
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="contactInfo" className="col-sm-3 col-form-label">{currLang.contact}:</label>
          <div className="col-sm-9">
          <textarea 
            name="contactInfo" 
            onChange={handleChange} 
            className="form-control" 
            required="required" 
            rows="3" 
            value={formData.contactInfo}
          />
          <small className="form-text text-muted">
             {currLang.contactDesc}
          </small>             
          </div>
        </div>
        <div className="clearfix">
        <div  className="form-group row float-right">
          <div className="col-sm-9">
            <div className="form-check">
              <input className="form-check-input" onChange={handleChange} type="radio" name="payment" value="month" />
              <label className="form-check-label" htmlFor="package">
                {currLang.monthly}*
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" onChange={handleChange} checked={formData.payment !== "month"} type="radio" name="payment" value="year" />
              <label className="form-check-label" htmlFor="package" >
                {currLang.year}
              </label>
            </div>
          </div>
        </div>
      </div>
        { /*!formData.payed ? (
        <div  className="form-group row">
          <div className="col-sm-3">
            <p>{currLang.package}:</p>
          </div>
          <div className="col-sm-9">
            <div className="form-check">
              <input className="form-check-input" onChange={handleChange} type="radio" name="package" value="single" />
              <label className="form-check-label" htmlFor="package">
                {currLang.single}
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" onChange={handleChange} type="radio" name="package" value="tripple" />
              <label className="form-check-label" htmlFor="package" >
                {currLang.tripple}
              </label>
            </div>
          </div>
        </div>
        ) : ( <></>) */}
        {!formData.payed ? ( 
        <div>
          <div className="clearfix">
            <table className="table table-sm col-sm-6 float-right">
              <tbody>
                <tr>
                  <th>{currLang.total}</th>
                  <td className="text-right">{cost.total} {props.price[props.language].sign}</td>
                </tr>
              </tbody>
            </table>
            {formData.package === 'tripple'? (
              <div className="col-sm-4 align-middle float-right">{currLang.extraOrderInfo}</div>
            ) : ( <></>) }
          </div>
          <div className="form-group clearfix">
            <button 
              disabled={formData.package !== 'single'} 
              name="payNow" 
              type="submit" 
              className="btn btn-success float-right login-btn" 
              onClick={handleChange} 
              value="true">{currLang.payNow}</button>
            <button 
              name="payNow" 
              type="submit" 
              className="btn btn-warning float-right login-btn mr-2" 
              onClick={handleChange} 
              value="false">{currLang.payLater}</button>
          </div>
          <div className="small-desc"><span className="upper">*</span>{currLang.explain}</div>
        </div>
      ) : (<></> ) }
      </form>
  </div>
  );
}
