import './ResetPassword.css';
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth } from "../../../config/firebase-config";
import { sendPasswordReset } from "../../../service/auth";
import lang from './Lang';
// TODO: design
function ResetPassword(props) {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [currLang, setcurrLang] = useState(lang['en']);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (loading) return;
    if (user && user.name) navigate("/dashboard");
    if (props.language) setcurrLang(lang[props.language]);
  }, [user, loading]);

  return (
    <div className="reset">
      <h1>{currLang.header}</h1>
      <div className="reset__container">
        <input
          type="text"
          className="reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={currLang.email}
        />
        <button
          className="reset__btn"
          onClick={() => sendPasswordReset(email)}
        >
          {currLang.send}
        </button>
        <div>
          {currLang.noAccount} <Link to="/user/register">{currLang.register}</Link> 
        </div>
      </div>
    </div>
  );
}
export default ResetPassword;