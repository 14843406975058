import './NavBar.css';
import React, { useEffect,useState } from "react";
import { NavLink } from "react-router-dom";
import Profile from "../Profile/Profile"
import lang from './Lang';

export default function NavBar(props) {
  const [currLang, setcurrLang] = useState(lang['en']);

  useEffect(() => {
    if (props.language) setcurrLang(lang[props.language]);
  }, [props.language]);
  
  if(currLang === undefined) return ( <></>); // TODO: better fix for iphone

  return(
    <nav className="navbar navbar-expand-md fixed-top">
      <button className="navbar-light navbar-toggler" type="button" data-toggle="collapse" data-target="#navbars" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <h1><a href="/">Return2us</a></h1>
      <div className="collapse navbar-collapse" id="navbars">
        <ul className="navbar-nav mr-auto">
          {currLang &&  Object.keys(currLang['main']).map((key,index) => (
            <li key={index.toString()}>
              <NavLink 
                end 
                className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} 
                to={key}>
                  {currLang['main'][key]}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <ul className="navbar-nav mr-auto" id="profile">
        <li className="nav-item">
          {props.user && props.user !== "undefined" ? (
            <Profile user={props.user} language={props.language}/>
          ) : (
          <NavLink className="btn btn-action btn-block" to="/login">{currLang['sign in']}</NavLink>
          )}
        </li>
      </ul>
    </nav>
  )
}