const Lang = {
  'en': {
    'information' :'Information',
    'info' : {
      '/cookies' : 'Cookie Policy',
      '/terms' :'Terms and Conditions'
    },
    'contact us' : 'Contact Us',
    'contact': {
      '/support' : 'Contact us',
      'companies' : 'For companies',
      '/support#help' : 'Want to help?'
    },
    'language':'Language'
  },
  'se': {
    'information' :'Information,',
    'info' : {
      '/cookies' : 'Cookie Policy',
      '/terms' :'Terms and Conditions'
    },
    'contact us' : 'Kontakta oss',
    'contact': {
      '/support' : 'Kontakta oss',
      'companies' : 'För företag',
      '/support#help' : 'Vill du bli en del av oss?'
    },
    'language':'Språk'
  }
}

export default Lang;