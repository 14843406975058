export default function body(props) {

  if (props.language === 'se') {
    return (
      <div class="full-width">
        <h2>Hur fungerar det ?</h2>
        <p>
          Med en QR kod på dina saker så kan personen som hittar det du har tappat så kan dom återlämna det du har tappat till dig. 
          Varför skulle då någon lägga tid på att du ska få tillbaka det du har tappat ? Jo dom kommer få en hittelön för att dom ska hjälpa dig få tillbaka ditt förlorade föremål. 
          <b>Vad skulle det vara värt för dig att få tillbaka din telefon eller laptop som du har glömt eller tappat någonstans ?</b>
        </p>
      </div>
    )
  } else {
    return (
      <div class="full-width">
        <h2>How does it work ?</h2>
        <p>
          First you register you item here. You will get an Qr code to put on your item and if you lose it, 
          and when someone some finds it, they will scan the Qr code and they can contact you and you can get your item back. 
          Why would they do that you might think ? Because there is a finders fee. The person that finds you item will earn money off it.<br/>
          <b>How much is it worth to you to get your mobile or laptop returned to you if you lost it ? </b>
        </p>
      </div>
    )
  }
}