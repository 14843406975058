const Lang = {
  'en': {
    'header':'Contact us',
    'email' : 'Email',
    'name':'Name',
    'send':'Send',
    'message':'Message'

  },
  'se': {
    'header':'Kontakta oss',
    'email' : 'Email',
    'name':'Namn',
    'send':'Skicka',
    'message':'Meddelande'

  }
}

export default Lang;

// Har du någonsin tappat något och önskat att det vore något bra sätt att få tillbaka det ?
