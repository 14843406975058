import React, { useReducer, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../../service/db";
import lang from './Lang';

const formReducer = (state, event) => {
  if(event.reset) {
    let  returnData = {};
    return {
      returnData
    }
  }
  if(event.ref){
    return event;
  }
  return {
    ...state,
    [event.name]: event.value
  }
 }

export default function RegAdress(props) {
  const navigate = useNavigate();
  const [formData, setFormData] = useReducer(formReducer, {});
  const [currLang, setcurrLang] = useState(lang['en']);

  const user = props.user;

  useEffect(() => {
    if (!user) return;
    if (user && !user.name) navigate("/login");
    if (props.language) setcurrLang(lang[props.language]);

    AddUserInfo();
  }, [user.name]);

  function AddUserInfo(){
    let adress = JSON.parse(localStorage.getItem('adress'));
    if(adress){
      Object.keys(adress).forEach(key => {
        setFormData({name: key, value: adress[key]});
      });
    } else {
      const name = user.name.split(" ");
      if(name.length > 1){
      setFormData({name: 'firstname', value: name[0]});
      setFormData({name: 'lastname', value: name[1]});
      }
      setFormData({name: 'email', value: user.email});
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    console.log(formData);
    //TODO: validate and send payment + form
    localStorage.setItem('adress', JSON.stringify(formData));
    if(formData.save) {
      registerAdress(formData);
    }
  }

  const handleChange = e => {
    const isCheckbox = e.target.type === 'checkbox';
    setFormData({
      name: e.target.name,
      value: isCheckbox ? e.target.checked : e.target.value,
    })
  }

  const registerAdress = async (data) => {
    try {
      let updatedUser = user;
      updatedUser.adress = data;
      const ref = await updateUser(updatedUser);
      navigate("/order");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
    <div className="container wrapper">
      <h1>{currLang.header}</h1>
      <div className="m-3">{currLang.lead}</div>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="firstname">{currLang.firstName}</label>
            <input type="text" className="form-control" onChange={handleChange} name="firstname" value={formData.firstname}/>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="lastname">{currLang.lastName}</label>
            <input type="text" className="form-control" onChange={handleChange} name="lastname" value={formData.lastname}/>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="email">{currLang.email}</label>
            <input type="email" className="form-control" onChange={handleChange} name="email" value={formData.email}/>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="co">{currLang.co}</label>
          <input type="text" className="form-control"onChange={handleChange} name="co" placeholder={currLang.coDesc} value={formData.co}/>
        </div>
        <div className="form-group">
          <label htmlFor="address">{currLang.address}</label>
          <input type="text" className="form-control"onChange={handleChange} name="address" placeholder="1234 Main St" value={formData.address}/>
        </div>
        <div className="form-group">
          <label htmlFor="address2">{currLang.address2}</label>
          <input type="text" className="form-control"onChange={handleChange} name="address2" placeholder={currLang.address2Desc} value={formData.address2}/>
        </div>
        <div className="form-row">
          <div className="form-group col-md-5">
            <label htmlFor="city">{currLang.city}</label>
            <input type="text" className="form-control"onChange={handleChange} name="city" value={formData.city}/>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="state">{currLang.state}</label>
            <input type="text"onChange={handleChange} name="state" className="form-control" value={formData.state}/>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="zip">{currLang.zip}</label>
            <input type="text" className="form-control" onChange={handleChange} name="zip" value={formData.zip}/>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="country">{currLang.country}</label>
            <input type="text" className="form-control" onChange={handleChange} name="country" value={formData.country}/>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group form-check ml-2">
            <input type="checkbox" className="form-check-input" onChange={handleChange} name="save" checked={formData.save}/>
            <label className="form-check-label" htmlFor="save">{currLang.save}</label>
          </div>
        </div>
        <button type="submit" className="btn btn-success mb-4">{currLang.send}</button>
      </form>
    </div>
  )
}
