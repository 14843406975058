const Lang = {
    'en': {
      'buttonText':'Accept our cookies to continue',
      'browserLang':'<b>Your language is set to English</b> according to the language of your browser. You can change this at the bottom right corner of the site.'
    },
    'se': {
      'buttonText':'Acceptera våra cookies för att fortsätta',
      'browserLang':'<b>Språket på sajten är satt till Svenska</b> baserat på språket av din webbläsare. Du kan ändra detta i nedre högra hörnet av sajten.'
    }
}

export default Lang;