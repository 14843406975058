import { auth } from "../config/firebase-config";
import {
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from 'firebase/auth';
import { addUser, addNonSocUser } from './db';


const socialMediaAuth = (provider) => {
  return signInWithPopup(auth, provider)
  .catch((err) => {
    console.error(err);
    alert(err.message);
  })
};


const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    addNonSocUser({
      uid: user.uid,
      name: name,
      email: email
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);

};

export { socialMediaAuth, registerWithEmailAndPassword, logInWithEmailAndPassword, sendPasswordReset,logout };