const Lang = {
  'en': {
    'header':'Register',
    'signInWith': 'Sign in with',
    'signIn' : 'Sign in',
    'remember' : 'Remember me',
    'or':'or',
    'email': 'email',
    'haveAccount':'Do you have an account?',
    'login': 'Login',
    'password': 'password'
  },
  'se': {
    'header':'Registera',
    'signInWith': 'Registera med',
    'signIn' : 'Logga in',
    'or':'eller',
    'remember' : 'Kom ihåg mig',
    'email': 'email',
    'haveAccount':'Har du ett konto?',
    'login': 'Logga in',
    'password': 'lösenord'
  }
}

export default Lang;