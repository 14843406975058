import React, { useEffect,useState } from "react";
import parse from "html-react-parser";
import lang from './Lang';

export default function Cookies(props) {
  const [currLang, setcurrLang] = useState(lang['en']);

  useEffect(() => {
    if (props.language) setcurrLang(lang[props.language]);
  }, [props.language]);

  return (
    <div>
      <div className="jumbotron">
        <h1 className="display-4">{currLang.header}</h1>
        <p className="lead">{currLang.lead}</p>
        <hr className="my-4" />
      </div>
      <div>
        {parse(currLang.body)}
      </div>
    </div>
  )
}
