const Lang = {
  'en': {
    'links' : {
      '/dashboard' : 'Dashboard',
      '/product/register' :'Register a product',
      '/user/register-adress': 'My adress',
      '/order':'Activate products'
    },
    'loggedIn' : 'Logged in as',
    'logout':'Logout'
  },
  'se': {
    'links' : {
      '/dashboard' : 'Min sida',
      '/product/register' :'Registera en produkt',
      '/user/register-adress': 'Min adress',
      '/order':'Aktivera produkter'
    },
    'loggedIn' : 'Inloggad som',
    'logout':'Logga ut'
  }
}

export default Lang;