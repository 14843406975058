import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAkYXb0Ww1GhgUfyzLcHtIGhmIZKL_ejC8",
  authDomain: "return2us.firebaseapp.com",
  projectId: "return2us",
  storageBucket: "return2us.appspot.com",
  messagingSenderId: "721237035356",
  appId: "1:721237035356:web:16305b39221047ca249999",
  measurementId: "G-QKTDQLLECS"
};

// Use this to initialize the firebase App
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };