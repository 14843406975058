export default function body(props) {

  if (props.language === 'se') {
    return (
      <div>
        <h1>Cookiepolicy</h1>
        <p>Denna webbplats kan använda cookies. Den här sidan beskriver hur och varför vi använder cookies.<br /></p>
        <h2>Hur vi använder cookies</h2>
        <p>Vi använder cookies av en mängd olika skäl som beskrivs nedan. 
          Tyvärr finns det i de flesta fall inga branschstandardalternativ för att 
          inaktivera cookies utan att helt inaktivera funktionaliteten och funktionerna 
          som de lägger till på denna webbplats. Det rekommenderas att du lämnar på alla 
          cookies om du är osäker på om du behöver dem eller inte i fall de används för att 
          tillhandahålla en tjänst som du använder.<br /></p>
        <h2>Inaktivera cookies</h2>
        <p>Du kan förhindra inställningen av cookies genom att justera inställningarna i 
          din webbläsare (se webbläsarens hjälp för hur du gör detta). Var medveten om 
          att inaktivering av cookies kommer att påverka funktionen hos denna och många 
          andra webbplatser som du besöker. Inaktivering av cookies kommer också att 
          resultera i att vissa funktioner och funktioner på denna webbplats inaktiveras. 
          Därför rekommenderas det att du inte inaktiverar cookies.<br /></p>
        <h2>Kontorelaterade cookies</h2>
        <p>Om du skapar ett konto hos oss kommer vi att använda cookies för hantering av 
          registreringsprocessen och allmän administration. Dessa cookies kommer vanligtvis 
          att raderas när du loggar ut; men i vissa fall kan de finnas kvar efteråt för att 
          komma ihåg dina webbplatsinställningar när du är utloggad.<br /></p>
        <h2>Inloggningsrelaterade cookies</h2>
        <p>Vi använder cookies när du är inloggad så att vi kan komma ihåg att du är inloggad. 
          Detta förhindrar att du behöver logga in varje gång du besöker en ny sida. 
          Dessa cookies tas vanligtvis bort eller rensas när du loggar ut för att säkerställa 
          att du bara kan komma åt begränsade funktioner och områden när du är inloggad.<br /></p>
        <h2>Formulärrelaterade cookies</h2>
        <p>När du skickar data till oss via ett formulär som de som finns på kontaktsidor eller 
          kommentarsformulär, kan cookies ställas in för att komma ihåg dina användaruppgifter 
          för framtida korrespondens.<br /></p>
        <h2>Tredjepartscookies</h2>
        <p>Vi använder endast ett fåtal tredjepartscookies. Vi använder Facebook, Twitter och 
          Googles api för att registrera och logga in för att användarna ska övertyga. 
          Vi använder även Google Tag Manager för att hjälpa oss att förbättra användarupplevelsen. 
          Men vi spårar INTE några personuppgifter.<br /></p>
        <h2>Vilken information vet vi om dig?</h2>
        <p>Vi begär eller kräver inte att du lämnar personlig information för att komma åt vår webbplats. 
          Vi kräver åtminstone ett e-postmeddelande om du vill registrera dig. 
          Vi kan få din IP-adress och användaragent automatiskt.</p>
      </div>
    )
  } else {
    return (
      <div>
        <h1>Cookie Policy</h1>
        This site may use cookies. This page describes how and why we use cookies.<br />
        <h2>How We Use Cookies</h2>
        We use cookies for a variety of reasons detailed below. Unfortunately in most
        cases there are no industry standard options for disabling cookies without
        completely disabling the functionality and features they add to this site. It is
        recommended that you leave on all cookies if you are not sure whether you need
        them or not in case they are used to provide a service that you use.<br />
        <h2>Disabling Cookies</h2>
        You can prevent the setting of cookies by adjusting the settings on your browser
        (see your browser Help for how to do this). Be aware that disabling cookies will
        affect the functionality of this and many other websites that you visit.
        Disabling cookies will result in also disabling certain functionality and
        features of this site. Therefore, it is recommended that you do not disable
        cookies.<br />
        <h2>Account related cookies</h2>
        If you create an account with us, then we will use cookies for the management of
        the signup process and general administration. These cookies will usually be
        deleted when you log out; however, in some cases they may remain afterwards to
        remember your site preferences when logged out.<br />
        <h2>Login related cookies</h2>
        We use cookies when you are logged in so that we can remember that you are
        logged in. This prevents you from having to log in every single time you visit a
        new page. These cookies are typically removed or cleared when you log out to
        ensure that you can only access restricted features and areas when logged in.<br />
        <h2>Forms related cookies</h2>
        When you submit data to us through a form such as those found on contact pages
        or comment forms, cookies may be set to remember your user details for future
        correspondence.<br />
        <h2>Third Party Cookies</h2>
        We use only a few third party cookies. We use Facebook, Twitter, and Google\'s
        api\'s to register and login for the users convince.We also use Google Tag
        Manager to help us improve the user experience. But we do NOT track any personal
        data.<br />
        <h2>What information do we know about you?</h2>
        We don’t request or require you to provide personal information to access our
        web site. We do require at least an email if you like to register. We may
        receive your IP address and user agent automatically.
      </div>
      )    
  }

}