const Lang = {
  'en': {
    'header':'Final step',
    'intro': 'Select your items that you wish to activate. If you order more then 3 and pay by the year, you will get a discount.',
    'item' : 'Item',
    'view' : 'View',
    'edit':'Edit',
    'monthly':'Monthly',
    'year':'Year',
    'findFee': 'Finders fee',
    'package' : 'Package ($$quantity)',
    'discount':'Discount',
    'total':'Total',
    'order':'Order',
    'explain':'Minimum 6 months'
  },
  'se': {
    'header':'Sista steget',
    'intro': 'Välj dom saker du vill aktivera. Om du väljer mer än 3 och betalar per år så kommer du få rabatt.',
    'item' : 'Sak',
    'view' : 'Granska',
    'edit':'Redigera',
    'monthly':'Per månad',
    'year':'Per år',
    'findFee': 'Hittelön',
    'package' : 'Paket ($$quantity)',
    'discount':'Rabatt',
    'total':'Totalt',
    'order':'Beställ',
    'explain':'Minst 6 månader'
  }
}

export default Lang;